import * as React from "react";
import Col from "react-bootstrap/Col";
import {useState, useRef, useEffect, useCallback} from "react";
import { Collapse, Row } from "react-bootstrap";

import Layout from "../components/Layout";
import Extinguisher from "../static/images/extinguisher-img.webp";
import RedSign from "../static/images/red-sign-img.webp";
import GreenSign from "../static/images/green-sign-img.webp";
import ExtinguisherOnWall from "../static/images/extinguisher-on-wall.webp";
import MailIcon from "../static/images/mail-icon.svg";
import PhoneIcon from "../static/images/phone-icon.svg";
import ScrollDownText from "../static/images/scroll-down-text.svg";
import ScrollDownDot from "../static/images/scroll-down-dot.svg";
import ChevronUp from '../static/images/chevron-up-icon.svg';
import Offer from "../static/images/offer.pdf";
import useWindowDimensions from "../components/Layout/useWindowDimensions";
import { SEO } from "../components/SEO";
import {linkedInProfile, services} from "../constants/constants";

import "./Home.scss";

const IndexPage = () => {
  const [openedCollapse, setOpenedCollapse] = useState({"Szkolenia PPOŻ. i BHP0": true});
  const [reverseBtnColors, setReverseBtnColors] = useState(false);
  const aboutRef = useRef(null);
  const contactRef = useRef(null);
  const servicesRef = useRef(null);
  const linkedInBtnRef = useRef(null);
  const windowDimensions = useWindowDimensions();
  const isMobileLayout = windowDimensions.width <= 992;
  const floatingBtnClasses = reverseBtnColors ? `floating red-btn red-btn--reverse bold` : `floating red-btn bold`;

  const scrollHandler = useCallback(() => {
    const linkedInBtnPosition = linkedInBtnRef.current.getBoundingClientRect();
    const contactSectionPosition = contactRef.current.getBoundingClientRect();
    const servicesSectionPosition = servicesRef.current.getBoundingClientRect();
    const isIntersectingWithServices = linkedInBtnPosition.y < (servicesSectionPosition.y + servicesSectionPosition.height)
        && (linkedInBtnPosition.y + linkedInBtnPosition.height > servicesSectionPosition.y);
    const isIntersectingWithContact = linkedInBtnPosition.y < (contactSectionPosition.y + contactSectionPosition.height)
        && (linkedInBtnPosition.y + linkedInBtnPosition.height > contactSectionPosition.y)

    if (isIntersectingWithServices || isIntersectingWithContact)
    {
      setReverseBtnColors(true);
    } else {
      setReverseBtnColors(false);
    }
  }, [linkedInBtnRef.current, contactRef.current, servicesRef.current]);

  useEffect(() => {
    if (linkedInBtnRef.current && servicesRef.current && contactRef.current) {
        document.addEventListener("scroll", scrollHandler);
    }
    return () => {
      document.removeEventListener("scroll", scrollHandler);
    }
  }, [scrollHandler]);

  useEffect(() => {
    setTimeout(() => {scrollHandler();}, 1500);

  }, [scrollHandler]);

  const toggleCollapse = (uniqueKey) => {
    if (!openedCollapse[uniqueKey]) {
      setOpenedCollapse({ [uniqueKey]: true });
    } else {
      setOpenedCollapse({ [uniqueKey]: false });
    }
  };

  const scrollToContact = () => {
    contactRef.current.scrollIntoView();
  };

  const scrollToAbout = () => {
    aboutRef.current.scrollIntoView();
  };

  return (
    <Layout
      scrollToContact={scrollToContact}
      scrollToAbout={scrollToAbout}
      isMobileLayout={isMobileLayout}
    >
      <Row as="header" className="header">
        <Col xs={6} as="h1" className="header__text-wrap">
          <div className="header__text">
            <span>Usługi</span>
            <span>PPOŻ.</span>
            <span>i BHP</span>
          </div>
        </Col>
        <Col className="images">
            {!isMobileLayout && (
              <>
                <img
                  className="d-none d-lg-block image image--extinguisher-sign"
                  src={Extinguisher}
                  alt="extinguisher"
                />
                <img
                  className="d-none d-lg-block image image--green-sign"
                  src={GreenSign}
                  alt="green sign"
                />
              </>
            )}
            <img className="image image--red-sign" src={RedSign} alt="red sign" />
        </Col>
      </Row>

      <Row as="section">
        <Col className="subheading" xs={12}>
          <p className="subheading__text">
            Zapewniamy najwyższe standardy z zakresu{" "}
            <br className="d-none d-md-block" /> bezpieczeństwa pożarowego i BHP
          </p>
          <button className="scroll-down">
            <img
              className="scroll-down__text"
              src={ScrollDownText}
              alt="scroll down text"
            />
            <img
              className="scroll-down__dot"
              src={ScrollDownDot}
              alt="scroll down dot"
            />
          </button>
        </Col>
      </Row>

      <Row ref={servicesRef} as="section" className="services">
        <Col xs={12} lg={6} as="h2" className="services__text-wrap">
          <div className="services__text">
            <span>Zakres</span>
            <span>naszych</span>
            <span>usług</span>
          </div>
        </Col>

        <Col className="services__list" xs={12} lg={6}>
          {services.map((service, idx) => {
            const uniqueKey = service.title + idx;
            const titleClass = `service__title ${openedCollapse[uniqueKey] ? 'open' : ''} `

            return (
              <div className="service" key={uniqueKey}>
                <button
                  className={titleClass}
                  onClick={() => toggleCollapse(uniqueKey)}
                >
                  {service.title}
                  <img src={ChevronUp} alt="icon chevron up" />
                </button>
                <Collapse
                  key={uniqueKey + "-collapse"}
                  in={openedCollapse[uniqueKey]}
                >
                  <div className="service__content">{service.content}</div>
                </Collapse>
              </div>
            );
          })}
        </Col>
      </Row>

      <Row ref={aboutRef} className="about" as="section">
        <Col className="ps-0 pe-0 pe-lg-3" xs={12} lg={6}>
          <img
            loading="lazy"
            width={760}
            height={657}
            src={ExtinguisherOnWall}
            alt="extinguisher on wall"
          />
        </Col>
        <Col className="about__text" xs={12} lg={6}>
          <h3 className="about__heading">O firmie</h3>
          <p className="about__paragraph">
            Interpoż działa prężnie od 2019 roku, firmata została stworzona z
            pasji przez ekspertów powiązanych z daną dziedziną wieloletnim
            doświadczeniem.{" "}
          </p>
          <p className="about__paragraph">
            Jako firma jak i specjaliści zapewniamy największy standard usług w
            zależności od potrzeb, wyżej wymienione zakresy naszego działania
            mogą być elastycznie modyfikowane w zależności co Państwo
            potrzebują.
          </p>
          <p className="about__paragraph">
            Poniżej przedstawiamy plik zawierający dane dotyczące oferowanych
            przykładowych usług jakie możemy Państwu zapewnić, w razie
            niepewności zapraszamy do kontaktu.
          </p>
          <a className="red-btn" href={Offer} target="_blank" rel="noreferrer">
            Oferta
          </a>
        </Col>
      </Row>

      <Row ref={contactRef} as="section" className="contact">
        <Col className="contact__heading" xs={12} lg={6} as="h4">
          Kontakt
        </Col>
        <Col className="contact__content" xs={12} lg={6}>
          <div className="d-flex flex-column">
            <p className="contact__info">
              Jeśli masz więcej pytań na temat usług zostaw nam swoje dane{" "}
              <br className="d-none d-xxl-block" /> kontaktowe, oddzwonimy i
              chętnie odpowiemy na Twoje pytania.
            </p>
            <a className="contact__anchor" href="tel:+48691511756">
              <img loading="lazy" src={PhoneIcon} alt="phone icon" />{" "}
              691-511-756
            </a>
            <a className="contact__anchor" href="mailto:kontakt@interpoz.com">
              <img loading="lazy" src={MailIcon} alt="Mail icon" />{" "}
              kontakt@interpoz.com
            </a>
          </div>
        </Col>
      </Row>
      <a ref={linkedInBtnRef} href={linkedInProfile} className={floatingBtnClasses}>
        <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M4.94043 2.00002C4.94017 2.53046 4.7292 3.03906 4.35394 3.41394C3.97868 3.78883 3.46986 3.99929 2.93943 3.99902C2.409 3.99876 1.90039 3.78779 1.52551 3.41253C1.15062 3.03727 0.940165 2.52846 0.94043 1.99802C0.940695 1.46759 1.15166 0.958988 1.52692 0.584103C1.90218 0.209218 2.411 -0.00124153 2.94143 -0.000976312C3.47186 -0.000711096 3.98047 0.210257 4.35535 0.585517C4.73024 0.960777 4.9407 1.46959 4.94043 2.00002ZM5.00043 5.48002H1.00043V18H5.00043V5.48002ZM11.3204 5.48002H7.34043V18H11.2804V11.43C11.2804 7.77002 16.0504 7.43002 16.0504 11.43V18H20.0004V10.07C20.0004 3.90002 12.9404 4.13002 11.2804 7.16002L11.3204 5.48002Z" fill="currentColor"/>
        </svg>
      </a>
    </Layout>
  );
};

export default IndexPage;

export const Head = () => <SEO />;
