const services = [
  {
    title: "Szkolenia PPOŻ. i BHP",
    content:
      "Szkolenie prowadzone jest przez zawodowego strażaka, który brał udział w akcjach ratowniczo-gaśniczych. Składa się ona z części teoretycznej, praktycznej i dostosowane jest do danego obiektu, zaistniałych potrzeb.",
  },
  {
    title: "Instrukcje bezpieczeństwa",
    content:
      "Jest to bardzo ważny, prawnie wymagany dokument, który prawidłowo opracowany i odpowiednio wdrożony pomaga w sposób optymalny chronić ludzkie zdrowie, a nawet życie, środowisko naturalne oraz mienie.",
  },
  {
    title: "Dokumentacja PPOŻ.",
    content: "W ramach usługi odbioru lub kontroli budynku przez straż pożarną wykonujemy szereg działań mających na celu sprawdzenie budynku oraz przygotowanie całej dokumentacji pod względem stawianych wymagań.",
  },
  {
    title: "Ochrona PPOŻ. i BHP",
    content: "Nasza firma zapewnia stałą obsługę  firm z zakresu ochrony ppoż. i  bhp",
  },
  {
    title: "Przeglądy urządzeń",
    content: "Nasza firma wykonuje następujące przeglądy: hydrantów, gaśnic, systemu sygnalizacji pożarowej, przeciwpożarowego wyłącznika prądu, oświetlenia awaryjnego. Wykonuje także zabezpieczenia przeciwpożarowych przepustów instalacyjnych przez przegrody oddzieleń pożarowych w budynkach oraz opracowujemy szereg dokumentacji z zakresu ppoż. w zależności od potrzeb klienta.",
  },
];

const transition = {
  duration: 1.5,
  ease: [0.43, 0.13, 0.23, 0.96],
};

const returnElVariants = {
  exit: { opacity: 0, y: 50, transition },
  enter: { opacity: 1, y: 0, transition },
};

const slideInNav = {
  exit: { x: "100%", transition: { duration: 0.8, delay: 0.3, type: "spring" } },
  enter: { x: 0, transition: { duration: 0.8, delay: 0.3, type: "spring" } },
};

const linkedInProfile = "https://www.linkedin.com/in/robert-bujny-238238155/";

export { services, returnElVariants, slideInNav, linkedInProfile };
